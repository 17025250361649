import {
  Anchor,
  BackgroundImage,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTwitter, IconLock, IconMail, IconNumber, IconUser } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthApi, GeneralApi } from "../../apis";
import banner from "../../assets/banner_2.png";
import { Form } from "../../components/form";
import { OtpFieldInput } from "../../components/form/otp-field-input";
import { PasswordField } from "../../components/form/password-field";
import { TextField } from "../../components/form/text-field";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";
import { ReactComponent as Dot1 } from "./../../assets/dot1.svg";
import { ReactComponent as Dot2 } from "./../../assets/dot2.svg";
import { ReactComponent as Dot3 } from "./../../assets/dot3.svg";
import { ReactComponent as FinanceIcon } from "./../../assets/hero-icon-2.svg";
import DigitalEraBlack from "./../../assets/DigitalEraBlack.png";

const schema = yup.object({
  regNumber: yup.string().required("Заавал бөглөнө!"),
  username: yup.string().required("Заавал бөглөнө!"),
  password: yup.string().required("Заавал бөглөнө!"),
});

const schemaForgot = yup.object({
  email: yup.string().email("И-мэйл хаяг оруулна уу!").required("Заавал бөглөнө!").trim(),
  username: yup.string().required("Заавал бөглөнө!").trim(),
});

type IForm = {
  username: undefined | string;
  // partnerRef: undefined | string;
  regNumber: undefined | string;
  businessRef: undefined | string;
  password: undefined | string;
};

let __WINDOW_LOAD__ = false;

export default function Login() {
  const { classes, theme } = useStyles();
  const { accessToken, user, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isTablet } = useScreenDetector();
  const dispatch = useDispatch();

  const [business, setBusiness] = useState<{
    businessRef: string | null;
    regNumber: string | null;
  }>({
    businessRef: null,
    regNumber: null,
  });

  const [data] = React.useState<IForm>({
    username: undefined,
    password: undefined,
    businessRef: undefined,
    regNumber: undefined,

    // partnerRef: undefined,
  });

  useEffect(() => {
    setLoading(true);
    let authResult = new URLSearchParams(location.search);
    let finance = authResult.get("finance");

    if (!!location.search && finance === "financeModule") {
      setBusiness({
        businessRef: authResult.get("businessRef"),
        regNumber: authResult.get("regNumber"),
      });
    }

    if (accessToken && user) navigate("/");

    __WINDOW_LOAD__ = true;
    setLoading(false);
  }, [dispatch, location, navigate, accessToken, user]);

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      const res = await AuthApi.login({
        // ...values,
        businessRef: business.businessRef,
        password: values.password,
        regNumber: values.regNumber,
        username: values.username,
      });
      dispatch(authChange(res));

      const auth = await AuthApi.me();
      const resInit = await GeneralApi.init();

      dispatch(authMe(auth));
      dispatch(initGeneral(resInit));

      message.success("Амжилттай нэвтэрлээ");
      navigate("/");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  const onSubmitForgot = async (values: any) => {
    setLoading(true);
    try {
      const res = await AuthApi.forgot(values);

      if (res.userId) {
        dispatch(authChange(res));
        setAction(["otp"]);
        message.success(res?.message || "Хүсэлт амжилттай!");
      } else {
        message.error("Нэвтрэх нэр эсвэл и-мэйл ээ зөв эсэхийг шалгана уу.");
        setAction(["forgot"]);
        dispatch(authChange());
      }
      // navigate(`/verify/${res.userId}`);
    } catch (error: any) {
      message.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!location.search) {
      window.location.href = "https://cp.dehub.mn";
      dispatch(authChange());
    }
  }, [location]);

  if (!__WINDOW_LOAD__)
    return (
      <Box className={classes.layout}>
        <Center>
          <Loader />
        </Center>
      </Box>
    );
  else
    return (
      <Box className={classes.layout}>
        <div className={classes.dotWrapper}>
          <Dot1 className={classes.dot1} />
          <Dot2 className={classes.dot2} />
          <Dot3 className={classes.dot3} />
          <Dot3 className={classes.dot4} />
        </div>
        <Grid p={"lg"} gutter={"lg"}>
          <Grid.Col sm={12} md={12} lg={6} hidden={isTablet}>
            <Group position="center" w={"100%"} h={"100%"}>
              <Flex className={classes.zIndex1} justify="center" h={"100%"} direction="column" w={"600px"} p={"lg"}>
                <Text mb={"xl"} fw={600} fz={"42px"} color="white">
                  Санхүүжилтийн систем
                </Text>
                <Text component="p" color="white">
                  Бизнес хоорондын үнэ цэн нэмсэн түншлэлийн түүх, бизнес <br /> хоорондын мөнгөн урсгалд суурилсан санхүүжилтийн <br /> үйлчилгээ авах систем.
                </Text>
                <FinanceIcon />
                <Text component="p" color="white">
                  Энэхүү системд Digital Era Payment системтэй Supply Chain Finance, <br />
                  Distribution Chain Finance үйлчилгээний гэрээ байгуулж, <br />
                  байгууллагыг төлөөлөх эрх авсан ажилтан нэвтэрнэ.
                </Text>
              </Flex>
            </Group>
          </Grid.Col>
          <Grid.Col sm={12} md={12} lg={6} style={{ zIndex: 30 }}>
            <Flex h={"100%"} align={"center"} justify={"center"}>
              <BackgroundImage src={banner} maw={"450px"} radius={"md"}>
                <Box p={"xl"}>
                  <Box>
                    <Box ml={-11}>
                      <img src={DigitalEraBlack} alt="" height={64} />
                      <Space h={"lg"} />
                    </Box>
                    {action[0] === "forgot" ? (
                      <div>
                        <Form initialValues={{ email: undefined, username: undefined }} onSubmit={onSubmitForgot} validationSchema={schemaForgot}>
                          {() => {
                            return (
                              <Grid>
                                <Grid.Col h={90}>
                                  <TextField
                                    icon={<IconUser size={"1.2rem"} />}
                                    label="Нэвтрэх нэр"
                                    name="username"
                                    placeholder="Нэвтрэх нэр оруулна уу"
                                    required
                                  />
                                </Grid.Col>
                                <Grid.Col h={90}>
                                  <TextField
                                    icon={<IconMail size={"1.2rem"} />}
                                    label="Хэрэглэгчийн и-мэйл"
                                    name="email"
                                    placeholder="Хэрэглэгчийн и-мэйл оруулна уу"
                                    required
                                  />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                  <Divider labelPosition="right" label={<Anchor onClick={() => setAction([])}>Өмнөх хуудас?</Anchor>} />
                                  <Space h={"lg"} />
                                  <Button
                                    disabled={loading}
                                    variant="gradient"
                                    type="submit"
                                    size="md"
                                    w="100%"
                                    radius={"md"}
                                    style={{ background: theme.fn.linearGradient(95, "#3e166e", "#3e166e", "#34105e") }}>
                                    Нэвтрэх
                                  </Button>
                                </Grid.Col>
                              </Grid>
                            );
                          }}
                        </Form>
                      </div>
                    ) : (
                      <Form initialValues={{ ...data, regNumber: business?.regNumber }} onSubmit={onSubmit} validationSchema={schema}>
                        {() => {
                          return (
                            <Grid>
                              <Grid.Col h={90}>
                                <TextField
                                  disabled
                                  icon={<IconNumber size={"1.2rem"} />}
                                  label="Татвар төлөгчийн дугаар"
                                  name="regNumber"
                                  placeholder="Татвар төлөгчийн дугаар"
                                  required
                                />
                              </Grid.Col>
                              <Grid.Col h={90}>
                                <TextField
                                  icon={<IconUser size={"1.2rem"} />}
                                  label="Хэрэглэгчийн нэрээ оруулна уу:"
                                  name="username"
                                  placeholder="Хэрэглэгчийн нэр..."
                                  required
                                />
                              </Grid.Col>
                              <Grid.Col h={90}>
                                <PasswordField icon={<IconLock size={"1.2rem"} />} label="Нууц үг оруулах:" name="password" placeholder="Нууц үг..." required />
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <>
                                  <Divider labelPosition="right" label={<Anchor onClick={() => setAction(["forgot"])}>Нууц үг мартсан?</Anchor>} />
                                  <Space h={"lg"} />
                                  <Button
                                    radius={"md"}
                                    w={"100%"}
                                    disabled={loading}
                                    variant="gradient"
                                    type="submit"
                                    size="md"
                                    style={{ background: theme.fn.linearGradient(95, "#3e166e", "#3e166e", "#34105e") }}>
                                    Нэвтрэх
                                  </Button>
                                </>
                              </Grid.Col>
                            </Grid>
                          );
                        }}
                      </Form>
                    )}
                  </Box>
                  <LoadingOverlay visible={loading} />
                </Box>
              </BackgroundImage>
            </Flex>
          </Grid.Col>
        </Grid>
        <Box py={"lg"} px={"100px"}>
          <Divider color={"gray"} />
          <Flex py={"lg"} w={"100%"} justify={"space-between"}>
            <Text size={"sm"} color="white">
              © 2024, Digital Era Payment
            </Text>
            <Text color="white">
              <Group>
                <IconBrandTwitter size={"1.2rem"} />
                <IconBrandFacebook size={"1.2rem"} />
                <IconBrandInstagram size={"1.2rem"} />
              </Group>
            </Text>
          </Flex>
        </Box>
        <Modal
          opened={action[0] === "otp"}
          onClose={() => setAction([])}
          title={
            <Text fw={500} fz={"lg"}>
              Нууц үг сэргээх
            </Text>
          }
          centered>
          <Form
            initialValues={{
              otpMethod: "FORGOT",
              otpCode: undefined,
            }}
            onSubmit={async (values: any) => {
              try {
                let res = await AuthApi.otpVerify(values);

                dispatch(authChange(res));
                setAction(["password"]);

                message.success(res?.message || "Хүсэлт амжилттай!");
              } catch (error: any) {
                message.error(error?.message);
              }
            }}
            validationSchema={yup.object({
              otpCode: yup
                .string()
                .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
                .typeError("Зөвхөн тоон утга оруулна")
                .max(6, "Хэт урт байна!")
                .min(6, "Заавал бөглөнө!")
                .required("Заавал бөглөнө!")
                .nullable(),
            })}>
            {() => {
              return (
                <Paper>
                  <OtpFieldInput name="otpCode" size="lg" />
                  <Space h={"sm"} />
                  <Button
                    disabled={loading}
                    variant="gradient"
                    type="submit"
                    size="md"
                    w="200px"
                    style={{ background: theme.fn.linearGradient(95, "#3e166e", "#3e166e", "#34105e") }}>
                    Хадгалах
                  </Button>
                </Paper>
              );
            }}
          </Form>
        </Modal>

        <Modal
          opened={action[0] === "password"}
          onClose={() => setAction([])}
          title={
            <Text fw={500} fz={"lg"}>
              Нууц үг сэргээх
            </Text>
          }
          centered>
          <Form
            initialValues={{
              password: "",
              passwordConfirm: "",
              oldPassword: null,
            }}
            onSubmit={async (values: any) => {
              try {
                let res = await AuthApi.password({
                  oldPassword: null,
                  password: values.password,
                });
                message.success(res.message || "Нууц үг амжилттай шинэчлэгдлээ.");
                setAction([]);
                dispatch(authChange());

                console.log(values);
              } catch (error: any) {
                message.error(error.message);
              }
            }}
            validationSchema={yup.object({
              password: yup.string().required("Заавал бөглөнө!").nullable(),
              passwordConfirm: yup
                .string()
                .oneOf([yup.ref("password"), null], "давтан нууц үг таарсангүй.")
                .required("Заавал бөглөнө!")
                .nullable(),
            })}>
            {() => {
              return (
                <Paper>
                  <PasswordField label="Шинэ нууц үг" placeholder="Шинэ нууц үгээ оруулна уу." name="password" />
                  <Space h={"sm"} />
                  <PasswordField label="Нууц үг давтах" placeholder="Нууц үгээ давтан оруулна уу. " name="passwordConfirm" />
                  <Space h={"sm"} />
                  <Divider h={"lg"} />
                  <Button
                    disabled={loading}
                    variant="gradient"
                    type="submit"
                    w="200px"
                    style={{ background: theme.fn.linearGradient(95, "#3e166e", "#3e166e", "#34105e") }}>
                    Хадгалах
                  </Button>
                </Paper>
              );
            }}
          </Form>
        </Modal>
      </Box>
    );
}

const useStyles = createStyles((theme) => ({
  layout: {
    height: "100%",
    background: "#3e166e",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  zIndex1: {
    zIndex: 1,
  },
  dotWrapper: {
    position: "relative",
    zIndex: 0,
  },
  dot1: {
    position: "absolute",
    top: "200px",
  },
  dot2: {
    position: "absolute",
    top: "400px",
    left: "200px",
  },
  dot3: {
    position: "absolute",
    left: "500px",
  },
  dot4: {
    position: "absolute",
    right: "0",
  },
  "&:mantine-Checkbox-label": {
    color: "#fdfeff !important",
  },
}));
