import { Global, MantineTheme, MantineThemeOverride } from "@mantine/core";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          border: "0 solid #e5e7eb",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    // golomt: ["#eafcff", "#ccf0ff", "#a0ddf9", "#6ec9f3", "#46b9ef", "#2bafec", "#12a9ec", "#0094d2", "#0084be", "#0072a8"],
    golomt: ["#d7f7f3", "#edd9f7", "#d8b1ea", "#3e166e", "#ae62d2", "#34105e", "#3e166e", "#3e166e", "#7b2aa0", "#6b218d"],
  },
  primaryColor: "golomt",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 14,
        },
      }),
    },
  },
};
